import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'lunch',
  formId: 'DK%20Lunch',
  title: 'Lunch',
  subTitle: 'It was love at first bite.',
  icon: '/icons/lunch.svg',
  image: '/assets/images/sunrise/lunch_supermercat.png',
  theme: 'sunrise-lunch',
  sections: [
    {
      sectionId: 'hero',
      label: 'Lunch',
      title: 'It was love at first bite.',
      description:
        'Do you want honest and delicious food?  Look no further! With a wide range of suppliers you can rest assured that your food will be seasonal with fresh ingredients for everyone. We have all price ranges, organic only, vegan or meat lover (or both?!) and if you don’t like the smell in the kitchen you’re welcome to try another one.',
      image: '/assets/images/sunrise/lunch_supermercat.png',
      icon: '/icons/lunch.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'It was love at first bite.',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/lunch/service_1.svg',
          title: 'Buffet',
          description:
            'The classic! Get an enticing variety of food  presented in style. Everything your heart desires on the day and a perfect setup for food cravings.',
        },
        {
          icon: '/assets/images/services/lunch/service_2.svg',
          title: 'Single serving',
          description:
            'Single serving is a good way to grab lunch on the fly... and to make sure everyone doesn’t sneeze on each others food',
        },
        {
          icon: '/assets/images/services/lunch/service_3.svg',
          title: 'Formal dining',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'A few steps till lunch is served',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/lunch/journey_1.svg',
          title: 'Tell us what you like',
          description: 'We would love to know what brings energy and satisfaction to your office.',
        },
        {
          icon: '/assets/images/services/lunch/journey_2.svg',
          title: 'Get a taster',
          description: 'We’ll make sure you taste the food before you settle on the perfect kitchen.',
        },
        {
          icon: '/assets/images/services/lunch/journey_3.svg',
          title: 'Choose your favorite provider',
          description: 'Of the different caterers you’ve tried, pick the one you love the most',
        },
        {
          icon: '/assets/images/services/lunch/journey_4.svg',
          title: 'Accept!',
          description: 'That’s it. Now its time to hit that buffet and boost your office energy.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love lunch through Good Monday',
      description: '',
      image: '/assets/images/services/lunch/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-activity-outline',
          title: 'Flexibility',
          description: 'More or less. You can adjust your lunch on a day-to-day basis!',
        },
        {
          icon: '',
          title: 'Get a test week',
          description: 'Quick tasting? You can test the lunch before signing up so you know what you like.',
        },
        {
          icon: 'eva eva-clock-outline',
          title: 'Super short termination period',
          description: '14 days! Beat that!',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that Good Monday has lunch for every taste... and wallet?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
